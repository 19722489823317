<script setup>
import {inject} from 'vue'
import homeFooterCtasJSON from 'assets/data/home/footerCtas.json'

import transitionConfig from '../helpers/transitionConfig'

const processedDataMenu = inject('processedDataMenu')
// const internalMenuNav = getInternalNavMenu(processedDataMenu?.value, 'sectors')

const {locale} = useI18n()
const parser = ParserUtils()

const config = useRuntimeConfig()

let endpoint = config.public.apiBase + locale.value + '/pages/homepage.json'
console.log('ENDPOINT: ', endpoint)

const {fetchedData} = await useDataStatic(endpoint)

const processedData = computed(() => {
  if (fetchedData.value) {
    return parser.homePage(fetchedData.value)
  }
  return null
})

const {injectScriptRecaptcha, deleteScriptRecaptcha} = useRecaptcha()

onMounted(() => {
  injectScriptRecaptcha()
  useAnimations()
})

onUnmounted(() => {
  deleteScriptRecaptcha()
})

definePageMeta({
  pageTransition: transitionConfig,
})

useHead({
  title: () =>
    processedData.value?.section.title
      ? `${processedData.value?.section.title}`
      : '',
  meta: [
    {
      name: 'description',
      content: () => `${processedData.value?.section.description}`,
    },
  ],
  bodyAttrs: {
    class: 'page page--home',
  },
})

useSeoMeta({
  ogTitle: () => `${processedData.value?.section.seo.title}`,
  description: () => `${processedData.value?.section.seo.description}`,
  ogDescription: () => `${processedData.value?.section.seo.description}`,
  ogImage: () => `${processedData.value?.section.seo.image}`,
  twitterCard: 'summary_large_image',
})

// modal and form code
import {useModalStore} from '~/store/modalStore'

import {onMounted} from 'vue'
const modalStore = useModalStore()
modalStore.setCurrentPage('home-page')
</script>

<template>
  <div>
    <h1 class="h-visually-hidden">Pininfarina</h1>
    <HomepageHero
      :content="processedData"
      :menuHero="processedData.section.hero"
      v-if="processedData" />
    <!-- <pre>{{ JSON.stringify(processedData, null, 2) }}</pre> -->
    <SharedMainContent :content="processedData" v-if="processedData" />
    <SharedModulesFooterCtas :content="homeFooterCtasJSON[locale]" />
    <!-- <SharedGeneralModal /> -->
    <ModalsPardotGeneral />
    <ModalsPardotProject />
    <SharedContactButton />
  </div>
</template>

<style lang="scss">
.page--home {
  .paragraph--introduction {
    position: relative;
    z-index: 2;
  }

  .paragraph--slider {
    margin-bottom: pxrem(80);

    .section-wrapper {
      @include make-col-ready;

      @include mq(lg) {
        @include make-col(20);
        @include make-col-offset(2);
      }
    }

    .swiper-button {
      .btn.btn--round {
        svg {
          stroke: $color-white !important;
        }

        &:hover {
          background-color: $color-white !important;
          border-color: $color-white !important;

          svg {
            stroke: $color-blue !important;
          }
        }
      }
    }
  }
}
</style>
